<template>
    <div class="main_container">
        <div class="main_page">
            <div class="page_title">{{ $route.meta.title }}</div>
            <div class="page_containt">
                <div class="page_search">
                    <el-form ref="searchForm" :model="searchForm">
                        <el-row type="flex">
                            <el-col class="search_col">
                                <el-form-item size="small" prop="key">
                                    <el-input size="small" type="text" v-model="searchForm.key" style="width: 180px"
                                        placeholder="直播间名称" />
                                </el-form-item>
                            </el-col>
                            <el-col class="search_col">
                                <el-form-item size="small" prop="begin_at">
                                    <el-date-picker size="small" style="width: 140px" v-model="searchForm.begin_at"
                                        type="date" value-format="yyyy-MM-dd" placeholder="直播开始日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col class="search_col" style="line-height: 32px"> 至 </el-col>
                            <el-col class="search_col">
                                <el-form-item size="small" prop="end_at">
                                    <el-date-picker size="small" style="width: 140px" v-model="searchForm.end_at"
                                        type="date" value-format="yyyy-MM-dd" placeholder="直播结束日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col class="search_col">
                                <el-button type="primary" size="small" native-type="submit"
                                    @click.native.prevent="handleSearch">搜索</el-button>
                            </el-col>
                            <el-col class="search_col">
                                <el-button size="small" @click="resetSearchForm()">重置</el-button>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div class="page_box" ref="pageBox">
                    <el-table v-loading="loading" row-key="id" size="small" class="el_table" cell-class-name="el_table_cell"
                        header-row-class-name="el_table_header" :data="listData" :height="tableHeight" style="width: 100%">
                        <el-table-column label="直播间头像" width="110">
                            <template slot-scope="scope">
                                <el-image style="width: 100px; height: 100px" :src="scope.row.web_vip_face" fit="contain"
                                    v-if="scope.row.web_face_url"></el-image>
                                <el-image style="width: 100px; height: 100px" src="/images/image-empty.png" fit="contain"
                                    v-else></el-image>
                            </template>
                        </el-table-column>
                        <el-table-column prop="title" label="直播间名称" min-width="300"> </el-table-column>
                        <el-table-column label="主播">
                            <template slot-scope="scope">
                                <el-row type="flex" align="middle" v-if="scope.row.vip">
                                    <el-col style="width:auto">
                                        <el-avatar :src="scope.row.vip.face_url" :size="40"></el-avatar>
                                    </el-col>
                                    <el-col style="width:auto;padding-left: 10px;">
                                        {{ scope.row.vip.name }}
                                    </el-col>
                                </el-row>
                                <div v-else>无</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="man_num" label="实际人数" width="100">
                        </el-table-column>
                        <el-table-column prop="goods_num" label="点赞数" width="100">
                        </el-table-column>
                        <el-table-column label="直播状态" width="100">
                            <template slot-scope="scope">
                                <div v-if="scope.row.status == 1">直播中</div>
                                <div v-if="scope.row.status == 2">未直播</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="created_at" label="开始时间" width="140">
                        </el-table-column>
                        <el-table-column prop="end_at" label="结束时间" width="140">
                        </el-table-column>
                    </el-table>
                    <div class="page_pagination">
                        <el-pagination background layout="total,prev,pager,next,jumper"
                            @current-change="handleCurrentChange" :total="pageConfig.counts"
                            :page-size="pageConfig.pageSize" :current-page="pageConfig.pageIndex">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { onTableHeight } from "@/lib/table";
import roomApi from "@/api/room";
export default {
    data() {
        return {
            loading: false,
            drawer: false,
            searchForm: {
                key: "",
                begin_at: "",
                end_at: ""
            },
            model: null,
            listData: [],
            pageConfig: { pageSize: 50, pageIndex: 1, counts: 0 },
            tableHeight: 500
        };
    },
    created() { },
    mounted() {
        onTableHeight(this);
        this.getList();
    },
    methods: {
        //查询
        handleSearch() {
            this.getList();
        },
        resetSearchForm() {
            if (this.$refs.searchForm) this.$refs.searchForm.resetFields();
            this.getList();
        },
        //列表
        getList(pageIndex = 1) {
            this.loading = true;
            roomApi
                .roomLogList({
                    ...this.searchForm,
                    page_size: this.pageConfig.pageSize,
                    page_index: pageIndex,
                })
                .then((res) => {
                    this.listData = res.list;
                    this.pageConfig.pageIndex = res.page.page_index;
                    this.pageConfig.counts = res.page.counts;
                })
                .catch(() => { })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleCurrentChange(val) {
            this.getList(val);
        },
    },
};
</script>
<style scope></style>
  